.adaptive-font-size {
    font-size: calc(12px + 3 * (100vw - 320px) / 880);
}

.body-container-fluid {
    background-color: rgb(243, 243, 243);
    padding: 0px;
}

.body-container {
    background-color: white;
    padding: 125px 0px 50px;
}

.k-serv-label {
    width: 115px;
}

.k-serv-label>svg {
    width: 115px;
}

.container {
    max-width: 1200px;
}

.page-container {
    padding: 20px 40px;
}

.page-container-header {
    text-align: center;
    padding-bottom: 40px;
    color: #FE4789;
}

hr {
    color: #878787;
}

a {
    color: #43AAE6;
    text-decoration: none;
}

.dropdown-item:active {
    background-color: #43AAE6;
}

.navbar>.container {
    display: initial;
}

.navbar {
    height: 125px;
    border-bottom: 1px solid #e9e9e9;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 10;
}

.nav-link {
    padding: 1px 10px 0px 10px;
    border-right: 1px solid #e9e9e9;
    height: 30px;
    margin-right: 5px;
}

.nav-link:hover {
    color: #43AAE6 !important;
    background: white;
}

.navbar-toggler {
    float: inline-end;
}

.get-sample-button {
    background: white;
    border: none;
    color: #FE4789;
    border-radius: 25px;
    font-weight: 700;
    font-size: 15px;
    padding: 5px 15px 5px 15px;
}

.get-more-news-info-button{
    background: #43aae6;
    border: none;
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    padding: 5px 15px 5px 15px;  
}

.whats-up-button {
    background: #59CE72;
    border: none;
    color: #ffffff;
    border-radius: 25px;
    font-weight: 600;
    font-size: 15px;
    padding: 10px 15px 10px 15px;
}

.main-page-production-preview-category-row {
    padding-bottom: 20px;
}

.container-with-package-with-milk {
    background-image: url(./../public/resources/PackageWithMilk.svg);
    background-repeat: no-repeat;
    background-color: #43aae6;
    background-size: cover;
    height: 720px;
}

.main-page-list-a {
    cursor: pointer;
    color: #A9A9A9;
    text-decoration: none;
    font-size: 18px;
}

.main-page-list-a :hover {
    color: #43aae6 !important;
}

.text {
    font-size: 17px;
    text-align: justify;
    line-height: 35px;
    text-indent: 30px;
}

.header {
    padding: 20px 0px 50px 0px;
}

.slogan {
    color: "#43aae6";
    font-weight: 600;
}

.product-card {
    border: 1px solid #e9e9e9 !important;
    border-radius: 0px !important;
    width: 100%;
    height: 100% !important;
    cursor: pointer;
    transition-property: scale;
    transition-duration: 1s;
}

.card-description {
    text-align: justify;
    line-height: 21px;
}

.product-card:hover {
    border: 1px solid #d5d5d5 !important;
    scale: 1.02;
}

.card-img {
    border-radius: 0px !important;
}

.check-icon {
    width: 55px;
    height: 50px;
    color: rgb(67, 170, 230);
}

.advantage-row {
    height: 165px;
    line-height: 23px;
}

.advantage-title {
    font-size: larger;
    font-weight: 500;
}

.carousel-caption {
    background: rgba(255, 255, 255, 0.8);
    color: #494949;
    left: 25%;
    right: 25%;
    padding-bottom: 0px;
}

#btn-back-to-top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    display: none;
    background-color: rgba(0, 0, 0, 0.0);
    border: none;
}

#arrowTop {
    animation: slide1 1s ease-in-out infinite;
    font-size: xxx-large;
    color: #FE4789;
}

#carousel-news-image {
    height: 500px;
    width: 100%;
    object-fit: cover;
}

@keyframes slide1 {

    0%,
    100% {
        transform: translate(0, -5px);
    }

    50% {
        transform: translate(0, 0);
    }
}

@media (min-width: 850px) and (max-width: 1100px) {
    .advantage-row {
        height: 200px;
    }
}

@media (max-width: 850px) {

    .body-container {
        padding: 100px 0px 50px;
    }

    .k-serv-label>svg {
        width: 85px;
    }

    .navbar {
        height: 95px;
    }

    .check-icon {
        width: 40px;
        height: 35px;
        color: rgb(67, 170, 230);
    }

    .advantage-row {
        padding-bottom: 55px;
        height: 50px;
    }

    .advantage-title {
        font-size: large;
        font-weight: 500;
    }

    .page-container-header {
        padding-bottom: 20px;
    }

    #carousel-news-image {
        height: 320px;
    }
}

@media (max-width: 620px) {
    .advantage-title {
        font-size: medium;
    }
}

@media (max-width: 575px) {

    .body-container {
        padding: 80px 0px 50px;
    }

    .k-serv-label>svg {
        width: 65px;
    }

    .navbar {
        height: 75px;
    }

    .nav-link {
        border: none;
    }

    .navbar-nav .dropdown-menu {
        border: none;
    }

    .advantage-title {
        font-size: medium;
    }

    .check-icon {
        width: 30px;
    }

    .page-container-header {
        padding-bottom: 15px;
    }
}

@media (max-width: 455px) {

    .page-container-header {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .page-container {
        padding: 5px 15px;
    }

    .advantage-row {
        padding-bottom: 50px;
    }

    .advantage-title {
        font-size: 15px;
    }

    .check-icon {
        width: 25px;
    }

    #carousel-news-image {
        height: 240px;
    }
}